import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'

import { State } from './reducer'
import { FormDetail } from '../types/data'

export type FormsState = {
	list: { [id: string]: FormDetail }
}

const initialState: FormsState = {
	list: {}
}

export const formsSlice = createSlice({
	name: 'forms',
	initialState,
	reducers: {
		saveForms: (state: FormsState, action: PayloadAction<FormDetail[]>) => {
			state.list = keyBy(action.payload, 'id')
		}
	}
})

export const getFormsList = (state: State) => state.forms.list

export const formsActions = {
	...formsSlice.actions
}
