import { useMemo } from 'react'
import { startsWith } from 'lodash'
import thunk from 'redux-thunk'

import { Middleware } from 'redux'
import { createLogger } from 'redux-logger'

import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './reducer'

import { StateAction } from '../types/state'

let store: any

function loggerFilter(state: object, action: StateAction) {
	if (startsWith(action.type, 'persist')) {
		return false
	}
	return true
}

export const setupStore = (preloadedState?: object) => {
	let middleware: Middleware[] = [thunk]

	if (process.env.NODE_ENV === 'development') {
		const logger = createLogger({
			collapsed: true,
			duration: true,
			predicate: loggerFilter
		})

		middleware = [...middleware, logger]
	}

	let _store = store ?? configureStore({
		reducer: rootReducer,
		middleware,
		preloadedState,
		devTools: process.env.NODE_ENV === 'development'
	})

	// After navigating to a page with an initial Redux state, merge that state
	// with the current state in the store, and create a new store
	if (preloadedState && store) {
		const state = {
			...store.getState(),
			...preloadedState,
		}

		_store = configureStore({
			reducer: rootReducer,
			middleware,
			preloadedState: state,
			devTools: process.env.NODE_ENV === 'development'
		})

		// Reset the current store
		store = undefined
	}

	// For SSG and SSR always create a new store
	if (typeof window === 'undefined') return _store

	// Create the store once in the client
	if (!store) store = _store

	return _store
}

export const useStore = (preloadedState?: object) => useMemo(() => setupStore(preloadedState), [preloadedState])
