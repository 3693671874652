import { combineReducers } from 'redux'

import { MenuState, menuSlice } from './menu'
import { BlogPostsState, blogPostsSlice } from './blog'
import { ThemesState, themesSlice } from './themes'
import { MapState, mapSlice } from './map'
import { FormsState, formsSlice } from './forms'

export type State = {
	menu: MenuState,
	blogPosts: BlogPostsState
	themes: ThemesState
	map: MapState
	forms: FormsState
}

const rootReducer = combineReducers({
	menu: menuSlice.reducer,
	blogPosts: blogPostsSlice.reducer,
	themes: themesSlice.reducer,
	map: mapSlice.reducer,
	forms: formsSlice.reducer
})

export default rootReducer
