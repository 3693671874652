import React from "react"
import { AppProps } from "next/app"
import Script from "next/script"
import { ThemeProvider } from "styled-components"
import { Provider } from "react-redux"
import { GlobalStyles } from "@bratislava/susedia-components"
import { theme } from "../utils/styles"

import "antd/dist/antd.css"
import "antd/lib/grid/style/index.css"
import "antd/lib/notification/style/index.css"
import "antd/lib/upload/style/index.css"
import "antd/lib/date-picker/style/index.css"
import "antd/lib/popover/style/index.css"

import "../styles/global.css"

import i18n from "../utils/i18n"
import { useStore } from "../redux"

function MyApp({ Component, pageProps }: AppProps) {
	const store = useStore(pageProps.initialReduxState)

	return (
		<>
			<Script
				strategy="afterInteractive"
				data-domain="bratislavskisusedia.sk"
				src="https://plausible.io/js/script.js"
			/>
			<Provider store={store}>
				<ThemeProvider theme={theme}>
					<GlobalStyles />
					<Component {...pageProps} />
				</ThemeProvider>
			</Provider>
		</>
	)
}

export default i18n.appWithTranslation(MyApp)
