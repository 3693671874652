import path from 'path'
import NextI18Next from 'next-i18next'

export enum NAMESPACE {
	TRANSLATION = 'translation',
	PAGES = 'pages',
	COMPONENTS = 'components',
	PATHS = 'paths',
	ERRORS = 'errors'
}

export const LANGUAGES = ['sk']

export const DEFAULT_LOCALE = 'sk'

const config = require('next/config').default()?.publicRuntimeConfig

const i18n = new NextI18Next({
	otherLanguages: LANGUAGES,
	defaultLanguage: DEFAULT_LOCALE,
	localeStructure: '{{lng}}/{{ns}}',
	localeSubpaths: config?.localeSubpaths,
	localePath: path.resolve(process.cwd(), 'public/locales'),
	debug: true,
	defaultNS: NAMESPACE.TRANSLATION,
	fallbackLng: DEFAULT_LOCALE,
	interpolation: {
		escapeValue: false
	},
	keySeparator: '.',
	shallowRender: true,
	lng: DEFAULT_LOCALE,
	load: 'languageOnly',
	ns: Object.values(NAMESPACE),
	nsSeparator: '|'
})

export const { useTranslation } = i18n

export default i18n
